import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import '../../Assets/Styles/Logo.scss'

import { useTheme } from '../../Context/Themes'

import { LCG } from '../../Assets/TS/LGC';

export default function CMDLogo() {
  const [currentLine, setCurrentLine] = useState(0);
  const { theme } = useTheme();
  const lineRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  const lcg = useMemo(() => new LCG(123456789), []);

  let title = [
    "     ___           ___           ___           ___           ___           ___           ___           ___           ___           ___      ",
    "    /\\__\\         /\\  \\         /\\  \\         /\\__\\         /\\  \\         /\\  \\         /\\  \\         /\\  \\         /\\  \\         /\\  \\     ",
    "   /::|  |       /::\\  \\       /::\\  \\       /:/  /        /::\\  \\       /::\\  \\       /::\\  \\       /::\\  \\       /::\\  \\        \\:\\  \\    ",
    "  /:|:|  |      /:/\\:\\  \\     /:/\\:\\  \\     /:/  /        /:/\\:\\  \\     /:/\\ \\  \\     /:/\\ \\  \\     /:/\\:\\  \\     /:/\\:\\  \\        \\:\\  \\   ",
    " /:/|:|  |__   /::\\~\\:\\  \\   /::\\~\\:\\  \\   /:/__/  ___   /::\\~\\:\\  \\   _\\:\\~\\ \\  \\   _\\:\\~\\ \\  \\   /:/  \\:\\  \\   /::\\~\\:\\  \\       /::\\  \\  ",
    "/:/ |:| /\\__\\ /:/\\:\\ \\:\\__\\ /:/\\:\\ \\:\\__\\  |:|  | /\\__\\ /:/\\:\\ \\:\\__\\ /\\ \\:\\ \\ \\__\\ /\\ \\:\\ \\ \\__\\ /:/__/ \\:\\__\\ /:/\\:\\ \\:\\__\\     /:/\\:\\__\\ ",
    "\\/__|:|/:/  / \\:\\~\\:\\ \\/__/ \\/_|::\\/:/  /  |:|  |/:/  / \\:\\~\\:\\ \\/__/ \\:\\ \\:\\ \\/__/ \\:\\ \\:\\ \\/__/ \\:\\  \\  \\/__/ \\/__\\:\\/:/  /    /:/  \\/__/ ",
    "    |:/:/  /   \\:\\ \\:\\__\\      |:|::/  /   |:|__/:/  /   \\:\\ \\:\\__\\    \\:\\ \\:\\__\\    \\:\\ \\:\\__\\    \\:\\  \\            \\::/  /    /:/  /      ",
    "    |::/  /     \\:\\ \\/__/      |:|\\/__/     \\::::/__/     \\:\\ \\/__/     \\:\\/:/  /     \\:\\/:/  /     \\:\\  \\           /:/  /     \\/__/       ",
    "    /:/  /       \\:\\__\\        |:|  |        ~~~~          \\:\\__\\        \\::/  /       \\::/  /       \\:\\__\\         /:/  /                  ",
    "    \\/__/         \\/__/         \\|__|                       \\/__/         \\/__/         \\/__/         \\/__/         \\/__/                   ",
  ]

  const replaceLines = useCallback(() => {
    for (let i = 0; i < title.length; i++) {
      let element = lineRefs.current[i];
      if (!element) return;
      let text = element.innerText;
      text = text.replace(/[^ ]/g, () => {
        return String.fromCharCode(lcg.next());
      });
      setTimeout(() => {
        if (!element) return;
        element.innerText = text;
      }, i * 35);
    }
  }, [title.length, lcg]);
  
  const deleteLines = useCallback(() => {
    for (let i = 0; i < title.length; i++) {
      setTimeout(() => {
        let element = lineRefs.current[i];
        if (!element) return;
        element.innerText = '.';
      }, i * 35);
    }
  }, [title.length]);

  useEffect(() => {
    setTimeout(() => {
      if (currentLine < title.length - 1) {
        setCurrentLine(currentLine + 1);
      }
    }, 20);
    if (currentLine === title.length - 1) {
      setTimeout(() => {
        replaceLines()
      }, 500);
      setTimeout(() => {
        deleteLines()
      }, 1000);
    }
  }, [currentLine, title.length, deleteLines, replaceLines]);

  return (
    <div className='w-full h-full flex flex-col justify-center lg:rotate-0 -rotate-90 logo'>
      {title.slice(0, currentLine + 1).map((line, index) => (
        <pre key={index} className='self-center flex flex-col'>
          <p className={`flex-auto text-center text-clip text-xs ${theme.secondary.textBold}`} key={index} ref={el => lineRefs.current[index] = el}>
            {line}
          </p>
        </pre>
      ))}
    </div>
  )
}
