import { useState, useEffect, Fragment, useRef } from 'react'

import diaraist1 from '../../../Assets/Img/Screenshots/Diaraist/scrs1.jpg'
import diaraist2 from '../../../Assets/Img/Screenshots/Diaraist/scrs2.jpg'
import diaraist3 from '../../../Assets/Img/Screenshots/Diaraist/scrs3.jpg'
import diaraist4 from '../../../Assets/Img/Screenshots/Diaraist/scrs4.jpg'

import discord1 from '../../../Assets/Img/Screenshots/DiscordBots/scrs3.jpg'
import discord2 from '../../../Assets/Img/Screenshots/DiscordBots/scrs2.jpg'
import discord3 from '../../../Assets/Img/Screenshots/DiscordBots/scrs1.jpg'

import mamuro1 from '../../../Assets/Img/Screenshots/MamuroMail/scrs1.jpg'

import Zoommarizer from '../../../Assets/Img/Projects/Zoommarizer.png'

import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'

import NewIcon from '../../Another/NewIcon'
import { Frame } from '../../Another/Frame'

type Project = {
    title: string,
    description: string,
    buttons: {
        name: string,
        url: string
    }[],
    images: string[],
    new: boolean
}

export default function Projects() {
    
    return (
        <div className='flex flex-col gap-4'>
            <Frame>
                <Title/>
            </Frame>
            <ProjectList/>
        </div>
    )
}

const Title = () => {
    const { lang } = useLanguage();

    return(<h1 className={`w-full text-center self-center text-sm -m-2`}> { lang.projects.title } </h1>)
}

const ProjectList = () => {
    const { lang } = useLanguage();

    const [projects, setProjects] = useState<Project[]>([])

    useEffect(() => {
        setProjects([{
            title: lang.projects.projects[0].title,
            description: lang.projects.projects[0].description,
            buttons: [
                {
                    name: lang.projects.projects[0].buttonsText[0],
                    url: 'https://es.fiverr.com/s/01wBWk'
                },
            ],
            images: [discord1, discord2, discord3],
            new: false,
        },
        {
            title: lang.projects.projects[6].title,
            description: lang.projects.projects[6].description,
            buttons: [
                {
                    name: lang.projects.projects[6].buttonsText[0],
                    url: 'https://github.com/nervesscat/easy_bot'
                },
            ],
            images: ['https://i.ibb.co/k5TCDmj/b-NMDiaaij-Px-ZLt-LQJCHVh.png'],
            new: true,
        }
        , {
            title: lang.projects.projects[1].title,
            description: lang.projects.projects[1].description,
            buttons: [
                {
                    name: lang.projects.projects[1].buttonsText[0],
                    url: 'https://github.com/nervesscat/VisionGPT'
                },
            ],
            images: ['https://camo.githubusercontent.com/de4e2aa318fa0ec6c984e88f101b86f1118c4ce753a6cfafc171e0fec45274fa/68747470733a2f2f692e696d6775722e636f6d2f583156516b54772e706e67',
                    'https://i.ibb.co/6y6kt9W/ezgif-1-8237dc7048.gif'],
            new: false,
        }, {
            title: lang.projects.projects[2].title,
            description: lang.projects.projects[2].description,
            buttons: [
                {
                    name: lang.projects.projects[2].buttonsText[0],
                    url: 'https://github.com/nervesscat/Zoommarizer'
                },
            ],
            images: [Zoommarizer],
            new: false,
        }, {
            title: lang.projects.projects[3].title,
            description: lang.projects.projects[3].description,
            images: [diaraist1, diaraist2, diaraist3, diaraist4],
            buttons: [],
            new: false,
        }, {
            title: lang.projects.projects[4].title,
            description: lang.projects.projects[4].description,
            buttons: [
                {
                    name: lang.projects.projects[4].buttonsText[0],
                    url: 'https://github.com/nervesscat/Enron-Database'
                },
            ],
            images: [mamuro1],
            new: false,
        }, {
            title: lang.projects.projects[5].title,
            description: lang.projects.projects[5].description,
            buttons: [
                {
                    name: lang.projects.projects[5].buttonsText[0],
                    url: 'https://github.com/nervesscat/Rotating-Cube'
                },
            ],
            images: ['https://i.ibb.co/vk4LxS8/out-6.gif'],
            new: false,
        }])
    }, [lang])

    return(
        <div className='flex flex-col gap-8'>
            {
                projects.map((project, index) => {
                    return (
                        <ProjectCard key={ index } project={ project } toLeft={ index % 2 === 0 } />
                    )
                })
            }
        </div>
    )
}

function ProjectCard(props: { project: Project, toLeft: boolean }) {
    const cardRef = useRef<HTMLDivElement  | null>(null);
    const isInsideRef = useRef(false);

    useEffect(() => {
        let i = 0;
        let id = setInterval(() => {
            if (!cardRef.current) return;
            if (!isInsideRef.current) {
                cardRef.current.style.top = `${Math.cos(i) * 20}px`;
                i += 0.1;
            }
        }, 50);

        return () => {
            clearInterval(id);
        };
    }, [cardRef]);

    const handleMouseEnter = () => {
        isInsideRef.current = true;
    };

    const handleMouseLeave = () => {
        isInsideRef.current = false;
    };

    return (
        <div className={`h-full w-full sm:w-fit xl:w-full relative overflow-x-clip 
            ${props.toLeft ? 'self-start' : 'self-end'}`} 
            ref={cardRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
            <Frame primary={false}>
                <div className='w-full h-fit sm:w-96 xl:w-full max-sm:flex max-sm:flex-col md:grid md:grid-cols-2 gap-4'>
                    <TitleAndGallery project={props.project}/>
                    <Description project={props.project}/>
                </div>
            </Frame>
        </div>
    )
}

const TitleAndGallery = ({ project } : { project : Project }) => {
    const { theme } = useTheme();

    return(
        <Fragment>
            <div className='w-full flex flex-col gap-2'>
                { project.new ? <NewIcon /> : null }
                <h1 className={`text-center truncate ${theme.secondary.textColor}`} title={project.title}>
                    {project.title}
                </h1>
                <Gallery images={project.images} />
            </div>
        </Fragment>
    )
}


const Description = ({ project } : { project: Project }) => {
    const { theme } = useTheme();

    return(
        <div className={`w-full h-fit flex flex-col gap-4 ${theme.secondary.textColor}`}>
            <p>
                { project.description }
            </p>
            <div className='flex gap-4'>
                {
                    project.buttons.map((button, index) => {
                        return (
                            <a href={button.url} 
                                className={`border-4 border-double ${theme.secondary.textColor} ${theme.misc.hoverBg} ${theme.secondary.borderColor} rounded-sm p-2`} 
                                key={index}>
                                {button.name}
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}

function Gallery(props : { images: string[] }) {
    const [index, setIndex] = useState(0)

    function changeImage() {
        if (index < props.images.length - 1) {
            setIndex(index + 1)
        } else {
            setIndex(0)
        }
    }

    function changeImageBack() {
        if (index > 0) {
            setIndex(index - 1)
        } else {
            setIndex(props.images.length - 1)
        }
    }

    return (
        <div className={`w-full h-96 rounded-md overflow-clip`}>
            <div className='relative w-full h-full'>
                <img className='object-cover absolute w-full h-full' alt='project, note editor' src={ props.images[index] }/>
                <button className={`px-4 self-center absolute inset-y-0 left-0 ${ props.images[1] ? '' : 'hidden'}`} value='<' onClick={
                    () => { changeImageBack() }}>
                    <h1>{'<'}</h1>
                </button>
                <button className={`px-4 self-center absolute inset-y-0 right-0 ${ props.images[1] ? '' : 'hidden'}`} value='>' onClick={
                    () => { changeImage() }}>
                    <h1>{'>'}</h1>
                </button>
            </div>
        </div>
    )
}