import { Fragment } from 'react'
import Bio from './Left/Bio'
// import ZincNet from './Left/ZincNet'
import Stars from './Right/Stars'
import Art from './Left/Art'
import Projects from './Left/Projects'
import Skills from './Left/Skills'
import Status from './Right/Status'
import Visitors from './Right/Visitors'
import ThemesAndSoundButtons from './Right/ThemesAndSoundButtons'
import ConnFailed from '../Another/ConnFailed'
// import Soundcloud from './Right/Soundcloud'
// import Kofi from './Right/Kofi'
import Ecco from './Left/Ecco'
import Planet from './Left/Planet'
import AIChat from './Left/AIChat'
import MusicPlayer from '../Another/Music/MusicPlayer'
import Weather from './Left/Weather'
import Zundamon from './Left/Zundamon'

import { useLogin } from '../../Context/Login'

export default function Body() {
  const { login } = useLogin();

  return (
    <div className='w-full h-fit px-4 sm:px-20 lg:px-40 xl:px-48 2xl:px-96 flex md:flex-row flex-col sm:justify-center gap-4'>
        <div className='md:w-2/3 xl:w-9/12 flex flex-col gap-8'>
          <Bio/>
          <MusicPlayer/>
          <div className='w-full h-full flex flex-col sm:grid sm:grid-row-3 sm:grid-cols-1 sm:grid-row-2 md:grid-cols-2 gap-4'>
            <Planet/>
            <div className='w-full h-fit sm:row-span-2'>
              <Weather/>
            </div>
            <div className='w-full h-96 sm:h-full sm:row-span-2'>
              <Zundamon/>
            </div>
          </div>
          <Ecco/>
          <Art/>
          <Projects/>
          <Skills/>
          <AIChat/>
        </div>
        <div className=' md:w-1/3 xl:w-3/12 h-fit flex flex-col gap-10'>
          <Stars/>
          <ThemesAndSoundButtons/>
          <Status/>
          {
            login ? 
            <Fragment>
              <Visitors/>
            </Fragment>
            : <ConnFailed isVertical={true}/>
          }
        </div>
    </div>
  )
}
