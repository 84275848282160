import { useEffect, useState } from 'react'
import CMDLogo from './CMDLogo'
import CMDLoading from './CMDLoading'
import { useTheme } from '../../Context/Themes'

import '../../Assets/Styles/RetroEffect.scss'
import '../../Assets/Styles/Logo.scss'

const defaultState = {
  isVisible: true,
  isAnimationLoaded: false,
}

function HeaderScreen() {
  const [loadingState, setLoadingState ] = useState(defaultState)
  const { theme } = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoadingState({...defaultState, isAnimationLoaded: true})
      setTimeout(() => {
        setLoadingState({...defaultState, isVisible: false})
      }, 2000)
    }, 1500)
    
  }, [])

  return (
    <div className={`h-full w-full fixed flex z-40 transition-transform duration-700 ${ loadingState.isVisible ? 'scale-100' : '-translate-y-full scale-0'}`}>
      <div className={`h-screen w-full transition-all duration-1000 ${ loadingState.isAnimationLoaded ? 'p-2 pb-8 sm:p-8 xl:p-16' : 'p-0 sm-p-0 xl:p-0'} `}>
        <div className={`relative h-full flex justify-center sm:border-4 ${theme.primary.borderColor} rounded-3xl overflow-clip`}>
          <div className='absolute top-0 w-full h-full z-20 rounded-md scanlines-header'></div>
          <div className='absolute top-0 w-full h-full bg-zinc-950'>
          { !loadingState.isAnimationLoaded ? <CMDLoading /> : <CMDLogo/> }        
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderScreen