import { useState, useEffect, Fragment } from "react";
import { useLanguage } from "../../../../Context/LanguageContext";
import { useTheme } from "../../../../Context/Themes";

import { ThemeKeys, ThemesEnum } from "../../../../Utils/Settings";

type ButtonStates = {
  [key in ThemesEnum]: boolean
};

const defaultButtonStates: ButtonStates = Object.values(ThemesEnum).reduce((acc, theme) => {
  acc[theme] = false;
  return acc;
}, {} as ButtonStates);

export const ThemesSelector = () => {
    const { lang } = useLanguage();
    const { theme, changeTheme } = useTheme();
  
    const [buttonStates, setButtonStates] = useState<ButtonStates>(defaultButtonStates);

    useEffect (() => {
      const changeButtonState = (theme: string) => {
        const bsTheme = theme as ThemeKeys;
        setButtonStates(() => {
          const newValue = { ...defaultButtonStates };
          newValue[bsTheme] = true;
          return newValue
        })
      }

      changeButtonState(theme.misc.style);
    }, [theme])

    const handleSwitch = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      changeTheme(e.currentTarget.getAttribute('data-theme') as ThemeKeys)
    };
  
    return(
      <Fragment>
        <h1 className={`text-center text-xl font-extrabold ${theme.secondary.textColor}`}>{ lang.settings.themes }</h1>
        <div className='w-full h-full flex flex-wrap gap-4 place-content-center'>
          <Interruptor state={buttonStates.nervessStyle} onClick={handleSwitch} themeId={ThemesEnum.nervessStyle} title="Nervesscat 🟪" color="🟪"/>
          <Interruptor state={buttonStates.retroStyle} onClick={handleSwitch} themeId={ThemesEnum.retroStyle} title="Retro 🟩" color="🟩"/>
          <Interruptor state={buttonStates.oldMacStyle} onClick={handleSwitch} themeId={ThemesEnum.oldMacStyle} title="Old Mac 🍎" color="⬛"/>
        </div>
      </Fragment>
    )
  }

interface InterruptorType {
  state: boolean,
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  themeId: string
  title: string;
  color: string;
}

const Interruptor = ({ state, onClick, themeId, title, color } : InterruptorType) => {
  const { theme } = useTheme();

  return(
    <div className="flex flex-col items-center gap-2">
      <div className={`w-2 h-2 transition-colors ${ state ? 'bg-green-400' : 'bg-red-500' } rounded-full`}/>
      <div className={`w-8 h-10 rounded-md ${ theme.secondary.bgColor }`} onClick={onClick} title={title} data-theme={themeId}>
        <div className="w-full h-full flex flex-col gap-1 p-1">
          <div className={`w-full h-1/2 transition-transform ${ theme.primary.bgColor } ${ state ? ''  : 'translate-y-full' } rounded-md`}>
          </div>
        </div>
      </div>
      <p className="text-sm">{ color }</p>
    </div>
  )
}