import Banner from '../../../Assets/Img/Misc/zundamon_banner.png'

import { Link } from 'react-router-dom';

const Zundamon = () => {
    return (
        <Link to="/zundamon">
            <div
                className="w-full h-full rounded-xl cursor-pointer transition-all duration-500 hover:scale-110"
                style={{ backgroundImage: `url(${Banner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            ></div>
        </Link>
    )
}

export default Zundamon;