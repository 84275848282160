import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import MainPage from './Components/MainPage';
import ZundamonChat from './Components/Routes/ZundamonChat';
import Error from './Components/Routes/Error';

import { ThemesProvider } from './Context/Themes';
import { LanguageProvider } from './Context/LanguageContext';
import { LowPerformanceProvider } from './Context/LowPerformance';
import FloatingIcon from './Components/Another/FloatingIcon';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage/>
    },
    {
      path: '/zundamon',
      element: <ZundamonChat/>
    },
    {
      path: '*',
      element: <Error/>
    }
  ])

  return (
    <div className='w-screen overflow-clip'>
      <ThemesProvider>
        <LanguageProvider>
          <LowPerformanceProvider>
            <FloatingIcon/>
            <RouterProvider router={router}/>
          </LowPerformanceProvider>
        </LanguageProvider>
      </ThemesProvider>
    </div>
  );
}

export default App;