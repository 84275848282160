import { useRef } from 'react'
import Body from "./MainPage/Body";
import Header from "./MainPage/Header";
import Foot from './MainPage/Foot';
import HeaderScreen from './Another/HeaderScreen';
import BackgroundCanvas from './Another/BackgroundCanvas';

import { useLowPerformance } from '../Context/LowPerformance';
import { LoginProvider } from '../Context/Login';

import './../Assets/Styles/RetroEffect.scss'

export default function MainPage() {
  const { lowPerformance } = useLowPerformance();

  const hiddenElement = useRef(null);

  return (
    <div ref={hiddenElement} className={`w-screen h-screen ${ lowPerformance ? '' : 'retro' }`}>
      <BackgroundCanvas/>
      <div className="flex flex-col h-full w-full overflow-y-scroll overscroll-x-none bg-blend-multiply z-0">
        <HeaderScreen/>
        <Header/>
        <LoginProvider>
          <Body/>
        </LoginProvider>
        <Foot/>
      </div>
      { !lowPerformance && <div className="scanlines-entire-screen"/> }
    </div> 
  )
}
