import { Fragment } from "react";
import { useTheme } from "../../../../Context/Themes";
import { useLanguage } from "../../../../Context/LanguageContext";

import { availableLanguages, LanguageKeys } from "../../../../Utils/Settings";

export const LanguageSelector = () => {
    const { theme } = useTheme();
    const { lang, actualLanguage, handleChangeLanguage } = useLanguage();

    return(
        <Fragment>
            <h1 className={`text-center text-xl font-extrabold ${theme.secondary.textColor}`}>{ lang.settings.language }</h1>
            <select className={`w-full self-center ${theme.primary.textColor} ${theme.primary.bgColor} rounded-md p-2`} value={actualLanguage} onChange={e => handleChangeLanguage(e.target.value as LanguageKeys)} title='Select language'>
                {
                availableLanguages.map((language, index) => {
                    return(
                    <option value={language.value} key={index}>{language.label}</option>
                    )
                })
                }
            </select>
        </Fragment>
    )
} 