import * as THREE from "three";

type City = {
	name: string;
	visits: number;
	latitude: number;
	longitude: number;
};

export class Planet3D {
	FOV = 50;

	CAMERA_X = 0;
	CAMERA_Y = 3;
	CAMERA_Z = 14;
	CAMERA_ROTATION_X = -0.22;
	CAMERA_ROTATION_Y = 0;

	color: string;
	mountRef: HTMLDivElement;
	texture: any;
	scene: THREE.Scene;
	camera: THREE.PerspectiveCamera;
	renderer: THREE.WebGLRenderer;
	sphere: THREE.Mesh;
	planet: THREE.Mesh;

	// CAMERA_X = 0
	// CAMERA_Y = 0
	// CAMERA_Z = 14
	// CAMERA_ROTATION_X = 0
	// CAMERA_ROTATION_Y = 0

	// CAMERA_X = 0
	// CAMERA_Y = -14
	// CAMERA_Z = 0
	// CAMERA_ROTATION_X = 1.57
	// CAMERA_ROTATION_Y = 0

	// CAMERA_X = 0
	// CAMERA_Y = -10
	// CAMERA_Z = 10
	// CAMERA_ROTATION_X = 0.753
	// CAMERA_ROTATION_Y = 0

	constructor(mountRef: HTMLDivElement, texture: any, color: string) {
		this.mountRef = mountRef;
		const textureLoader = new THREE.TextureLoader();
		this.texture = textureLoader.load(texture);
		this.color = color;

		this.scene = new THREE.Scene();

		this.camera = new THREE.PerspectiveCamera(this.FOV, 1, 0.1, 1000);
		this.createCamera();
		this.renderer = new THREE.WebGLRenderer();
		this.createRenderer();
		this.sphere = new THREE.Mesh();
		this.planet = new THREE.Mesh();
		this.createSphere();
	}

	public resize(width: number, height: number) {
		this.renderer.setSize(width, height);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
	}

	createCamera() {
		this.camera.position.x = this.CAMERA_X;
		this.camera.position.y = this.CAMERA_Y;
		this.camera.position.z = this.CAMERA_Z;
		this.camera.rotation.x = this.CAMERA_ROTATION_X;
		this.camera.rotation.y = this.CAMERA_ROTATION_Y;
	}

	createRenderer() {
		this.renderer.setSize(
			this.mountRef.clientWidth,
			this.mountRef.clientHeight
		);
		this.renderer.setClearColor(0x000000, 0.0);
	}

	createSphere() {
		const geometry = new THREE.SphereGeometry(5, 12, 12);
		const material = new THREE.MeshBasicMaterial({
			color: this.color,
			wireframe: true,
		});

		this.planet = new THREE.Mesh(geometry, material);
		this.scene.add(this.planet);

		const materialPlanet = new THREE.MeshBasicMaterial({
			side: THREE.FrontSide,
			map: this.texture,
		});

		const geometryPlanet = new THREE.SphereGeometry(4.5, 10, 10);
		this.sphere = new THREE.Mesh(geometryPlanet, materialPlanet);
		this.scene.add(this.sphere);
	}

	getDomElement() {
		return this.renderer.domElement;
	}

	traverseListAndAddPoints(cities: City[], recordVisits: number) {
		cities.forEach((city) => {
			let [x, y, z] = this.convertToEuclideanCoordinates(
				city.latitude,
				city.longitude
			);

			if (city.visits >= recordVisits * 0.8) {
				this.addPoint(x, y, z, 0x00ff00);
			} else if (city.visits >= recordVisits * 0.5) {
				this.addPoint(x, y, z, 0xffff00);
			} else if (city.visits >= recordVisits * 0.2) {
				this.addPoint(x, y, z, 0xff0000);
			} else {
				this.addPoint(x, y, z);
			}
		});
	}

	convertToEuclideanCoordinates(
		latitude: number,
		longitude: number
	): [number, number, number] {
		if (
			latitude > 90 ||
			latitude < -90 ||
			longitude > 180 ||
			longitude < -180
		) {
			throw new Error("Bad Latitude or Longitude");
		}
		const radius = 5;
		const phi = THREE.MathUtils.degToRad(90 - latitude); // polar angle
		const theta = THREE.MathUtils.degToRad(-longitude); // azimuthal angle
		const x = radius * Math.sin(phi) * Math.cos(theta);
		const y = radius * Math.cos(phi);
		const z = radius * Math.sin(phi) * Math.sin(theta);

		return [x, y, z];
	}

	addPoint(x: number, y: number, z: number, color = 0xff00ff) {
		const geometry = new THREE.SphereGeometry(0.1, 32, 32);
		const material = new THREE.MeshBasicMaterial({ color: color });
		const point = new THREE.Mesh(geometry, material);
		point.position.set(x, y, z);
		this.sphere.add(point);

		const lineGeometry = new THREE.BufferGeometry().setFromPoints([
			new THREE.Vector3(x, y, z),
			new THREE.Vector3(0, 0, 0),
		]);
		const lineMaterial = new THREE.LineBasicMaterial({ color: color });
		const line = new THREE.Line(lineGeometry, lineMaterial);
		this.sphere.add(line);
	}

	createLatLine(latitude: number) {
		const points = [];
		for (let i = 0; i < 360; i++) {
			let [x, y, z] = this.convertToEuclideanCoordinates(latitude, i);
			points.push(new THREE.Vector3(x, y, z));
		}

		const geometry = new THREE.BufferGeometry().setFromPoints(points);
		const material = new THREE.LineBasicMaterial({ color: 0xffffff });
		const line = new THREE.Line(geometry, material);

		this.sphere.add(line);
	}

	changeTexture(texture: any) {
		this.texture = texture;
		let material = this.sphere.material as THREE.MeshBasicMaterial;
		material.map = new THREE.TextureLoader().load(this.texture);
		if (material.map) {
			material.map.wrapS = THREE.RepeatWrapping;
			material.map.wrapT = THREE.RepeatWrapping;
			material.map.repeat.set(1, 0.8);
			material.map.offset.set(0, 0.03);
		}
	}

	animate() {
		this.planet.rotation.y += 0.01;
		this.sphere.rotation.y += 0.01;
		this.renderer.render(this.scene, this.camera);
		requestAnimationFrame(this.animate.bind(this));
	}

	dispose() {
		this.renderer.dispose();
	}
}
