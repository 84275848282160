import { useState, useContext, createContext, ReactNode } from "react";

import { ThemeKeys, ThemeType, themesMap, ThemesEnum } from "../Utils/Settings";

type ThemesContextType = {
    theme: ThemeType;
    changeTheme: (theme: ThemeKeys) => void;
}

const savingThemeToLocalStorage = (themeName : string) => {
    localStorage.setItem('theme', themeName)
}

const getThemeFromLocalStorage = (): string => {
    let theme = localStorage.getItem('theme')
    if (theme) {
      return theme;
    } else {
      savingThemeToLocalStorage(defaultTheme)
      return defaultTheme
    }
}

const changeScrollBarStyle = (theme: string) => {
  const themes = [ThemesEnum.nervessStyle, ThemesEnum.retroStyle, ThemesEnum.christmasStyle, ThemesEnum.oldMacStyle, ThemesEnum.halloweenStyle];
  
  themes.forEach(t => document.body.classList.remove(t));
  document.body.classList.add(theme);
}

const defaultTheme = ThemesEnum.nervessStyle as ThemeKeys;

export const ThemesContext = createContext<ThemesContextType>(
    {} as ThemesContextType
);

export const ThemesProvider = ({ children } : { children : ReactNode }) => {
    let startingTheme = getThemeFromLocalStorage() as ThemeKeys;
    
    let date = new Date()
    const month = date.getMonth();
    const day = date.getDate();

    if (month >= 10 || (month === 0 && day <= 6)) {
        startingTheme = ThemesEnum.christmasStyle;
    } else if (month === 9) {
        startingTheme = ThemesEnum.halloweenStyle
    }

    const [theme, setTheme] = useState<ThemeType>(themesMap[startingTheme]);
    
    const changeTheme = (themeName : ThemeKeys) => {
        const selectedTheme = themesMap[themeName] || themesMap[defaultTheme]
        changeScrollBarStyle(selectedTheme.misc.style);
        setTheme(selectedTheme);
        savingThemeToLocalStorage(themeName);
    };

    return(
        <ThemesContext.Provider value={{ theme, changeTheme }}>
            { children }
        </ThemesContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemesContext);

    if (!context){
        throw new Error('useTheme must be within ThemesProvider');
    }

    return context
}