import { ReactNode } from "react"
import { useTheme } from "../../Context/Themes"
import Marquee from "react-fast-marquee";

interface FrameType {
    children: ReactNode,
    title?: ReactNode,
    primary?: boolean,
    left?: boolean,
    movement?: boolean
}

export const Frame = ({
    children,
    title = null,
    primary = true,
    left = true,
    movement = false
}: FrameType) => {
    const { theme } = useTheme();

    return (
        <div className={`w-full h-fit rounded-xl p-3 relative ${ primary ? 
            `${theme.primary.bgColor} ${theme.primary.textColor} ` 
            : `${theme.secondary.bgColor} ${theme.secondary.textColor} `}}`
            }>
            {
                title ? (
                    <div className={`absolute w-fit p-2 rounded-t-lg overflow-clip
                        ${ left ? 'left-4' : 'right-4' }
                        -top-8 ${ primary ? 
                            `${theme.primary.bgColor} ${theme.primary.textColor} ${theme.primary.borderColor} ` 
                            : `${theme.secondary.bgColor} ${theme.secondary.textColor} ${theme.secondary.borderColor} `}`}> 
                        <div className={`flex justify-center items-center gap-4 whitespace-nowrap text-center`}>
                            {
                                movement ? <Marquee>{ title }</Marquee> : title
                            }
                            
                        </div>
                    </div>
                ) : null
            }
            <div className={`w-full p-4 rounded-xl
                ${ primary ? theme.primary.bgTextColor : theme.secondary.bgTextColor }`}>
                { children }
            </div>
        </div>
    )
}

