import { ReactNode, useState, createContext, useContext, useCallback, useEffect } from "react";
import { LanguageStruct, language, availableLanguages, LanguageKeys } from "../Utils/Settings";

interface LanguageContextType {
  lang: LanguageStruct;
  actualLanguage: LanguageKeys;
  handleChangeLanguage: (langKey: LanguageKeys) => void;
}

export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

export const LanguageProvider = ({ children } : { children: ReactNode }) => {
  const [lang, setLang] = useState(language.en);
  const [actualLanguage, setActualLanguage] = useState('en' as LanguageKeys);

  const setLanguageInLocalStorage = useCallback((langKey: string) => {
    localStorage.setItem('language', langKey);
  }, []);
  
  const getLanguageFromLocalStorage = useCallback(() => {
    const langLocal = localStorage.getItem('language');
    if (langLocal && langLocal in language) {
      return langLocal as keyof typeof language;
    }
    return null;
  }, []);

  const getLanguageFromBrowser = useCallback(() => {
    const browserLang = navigator.language || 'en';
    return browserLang.split('-')[0] as LanguageKeys;
  }, []);

  const checkIfLanguageIsAvailable = useCallback((langKey: string) => {
    return availableLanguages.some(item => item.value === langKey);
  }, []);

  const initializeLanguage = useCallback(() => {
    const langFromLocal = getLanguageFromLocalStorage();
    const langFromBrowser = getLanguageFromBrowser();

    if (langFromLocal) {
      setActualLanguage(langFromLocal);
      setLang(language[langFromLocal]);
    } else if (checkIfLanguageIsAvailable(langFromBrowser)) {
      setActualLanguage(langFromBrowser);
      setLang(language[langFromBrowser]);
      setLanguageInLocalStorage(langFromBrowser);
    } else {
      setActualLanguage('en');
      setLang(language.en);
      setLanguageInLocalStorage('en');
    }
  }, [
    getLanguageFromLocalStorage,
    getLanguageFromBrowser,
    checkIfLanguageIsAvailable,
    setLanguageInLocalStorage
  ]);

  const handleChangeLanguage = useCallback((langKey: LanguageKeys) => {
    if (langKey in language) {
      setActualLanguage(langKey);
      setLang(language[langKey as keyof typeof language]);
      setLanguageInLocalStorage(langKey);
    }
  }, [setLanguageInLocalStorage]);

  useEffect(() => {
    initializeLanguage();
  }, [initializeLanguage]);

  return (
    <LanguageContext.Provider value={{ lang, actualLanguage, handleChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be within LanguageProvider');
  }
  return context;
};