import React, { useState, useEffect } from 'react'

import RetroBannerOn from '../../Assets/Img/NervessLogo/bannernervess.png'
import CMDBannerOn from '../../Assets/Img/NervessLogo/CMDBannerOn.png'
import ChristmasBannerOn from '../../Assets/Img/NervessLogo/ChristmasBannerOn.png'
import HalloweenBannerOn from '../../Assets/Img/NervessLogo/HalloweenBannerOn.png'
import OldMacBannerOn from '../../Assets/Img/NervessLogo/OldMacBannerOn.png'

import { useTheme } from '../../Context/Themes';

import '../../Assets/Styles/CounterClock.css'

function Header() {
  const { theme } = useTheme();

  const [happyNewYear, setHappyNewYear] = useState(false)

  return (
    <div className='w-full flex justify-center p-6 sm:p-8 xl:p-10'>
        <div className='w-4/5 sm:w-3/5 flex flex-col items-center gap-4'>
          {
            theme.misc.style === 'nervessStyle' ?
            <NervessBanner/> :
            theme.misc.style === 'retroStyle' ?
            <CMDBanner/> :
            theme.misc.style === 'christmasStyle' ?
            <ChristmasBanner/> :
            theme.misc.style === 'halloweenStyle' ?
            <HalloweenBanner/> :
            theme.misc.style === 'oldMacStyle' ?
            <OldMacBanner/> :
            null
          }
          {
            happyNewYear && theme.misc.style === 'christmasStyle' ?
            <HappyNewYear/> :
            theme.misc.style === 'christmasStyle' ?
            <CounterClock setHappyNewYear={setHappyNewYear}/> :
            null
          }
        </div>
    </div>
  )
}

function NervessBanner(){
  return (
    <img className='imageneonretro' src={RetroBannerOn} alt='Nervesscat Logo'/>
  )
}

function CMDBanner(){
  return (
    <img className='imageneoncmd' src={CMDBannerOn} alt='Nervesscat Logo'/>
  )
}

function ChristmasBanner(){
  return (
    <img className='imageneonchristmas' src={ChristmasBannerOn} alt='Nervesscat Logo'/>
  )
}

function HalloweenBanner(){
  return (
    <img className='imageneonhalloween' src={HalloweenBannerOn} alt='Nervesscat Logo'/>
  )
}

function OldMacBanner(){
  return (
    <img className='imageneonoldmac' src={OldMacBannerOn} alt='Nervesscat Logo'/>
  )
}

function CounterClock({setHappyNewYear} : {setHappyNewYear : React.Dispatch<React.SetStateAction<boolean>>}){
  const [time, setTime] = useState('00 00:00:00')

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const nextYear = now.getFullYear() + 1
      const newYear = new Date(nextYear, 0, 1);
      const diff = newYear.getTime() - now.getTime();

      if (now.getMonth() === 0) {
        setHappyNewYear(true)
        clearInterval(interval)
      }

      if (diff < 0) {
        clearInterval(interval);
        setHappyNewYear(true)
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      setTime(`${days} days and ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} hours until ${nextYear}!`);
    }, 1000);

    return () => clearInterval(interval);
  }, [setHappyNewYear]);

  return(
    <div className='p-2 border-b-2 border-green-500'>
      <h1 className='text-2xl sm:text-3xl text-center text-green-500 clock'>{time}</h1>
    </div>
  )
}

function HappyNewYear(){
  return(
    <div className='p-2'>
      <h1 className='text-2xl sm:text-3xl text-center text-green-500 clock'>Happy New Year! 🎆✨🌟🎄</h1>
    </div>
  )
}

export default Header
