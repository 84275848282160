import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'
import { useLogin } from '../../../Context/Login'
import Marquee from 'react-fast-marquee';

import avatar from '../../../Assets/Img/Misc/nervesscat.gif'
import hat from '../../../Assets/Img/Misc/christmas_hat.png'

import { Frame } from '../../Another/Frame'

export default function Bio() {
    return (
        <div className={`w-full flex flex-col rounded-md`}>
            <Frame movement={true}>
                <div className='h-fit flex flex-row content-center gap-2 p-2'>    
                    <div className={`h-fit max-sm:flex max-sm:flex-col`}>
                        <AvatarFrame/>
                        <LanguageAdvertence/>
                        <LoginAdvertence/>
                        <Description/>         
                        <QuickFacts/>
                    </div>
                </div>
            </Frame>
        </div>
    )
}

function LoginAdvertence(){
    const { lang } = useLanguage();
    const { login } = useLogin();
    
    return(
        login ? null :
        <div className='w-full h-fit'>
            <p className='text-red-500 text-sm'><span className='animate-pulse'>⚠️</span>{ lang.bio.loginAdvertisement }</p>
        </div>
    )
}

function LanguageAdvertence(){
    const { lang } = useLanguage();
    
    return(
        lang.stars.title === 'Contact' ? null :
            <div className='w-full h-fit'>
                <p className='text-red-500 text-sm'><span className='animate-pulse'>⚠️</span> Languages are experimental, if you find something mispelled, please let it know to the bot 😽</p>
            </div>
    )
}

function AvatarFrame() {
    const { theme } = useTheme();
    const { lang } = useLanguage();

    return (
        <div className={`w-64 sm:w-40 max-sm:self-center overflow-clip flex flex-col rounded-md border-2 ${theme.primary.borderColor} bg-black sm:float-left sm:mr-4`}>
            <div className='relative w-40 h-40 self-center'>
                {
                    theme.misc.style === 'christmasStyle' ?
                    <img src={hat} className='absolute w-28 h-28 -top-12 left-3 z-10' alt='hat'/> :
                    null
                }
                <img src={avatar} className='w-full h-full relative z-0 rounded-md' alt='avatar'/>
                <div className='absolute top-0 w-full h-full z-20 rounded-md scanlines'></div>
            </div>
            <div className='w-full border-t-2 border-red-600 text-red-500 font-bold overflow-clip'>
                <Marquee>
                    <p className='w-full'>{ lang.bio.nervesscatMessage }</p>
                </Marquee>
            </div>
        </div>
    );
}

function Description(){
    const { lang } = useLanguage();

    return(
        <div>
            <p className='text-xl text-red-600 animate-pulse sm:visible hidden'>
                { lang.bio.pageWork }
            </p>
            <p className='sm:text-left text-justify max-sm:pt-4'> { lang.bio.description[0] }</p>
            <br/>
            <p className='sm:text-left text-justify'>
                { lang.bio.description[1] }
                <span className='text-green-500 font-bold'> { lang.bio.languages[0] } </span>, 
                <span className='text-blue-500 font-bold'>{ lang.bio.languages[1] } </span>, 
                <span className='text-yellow-500 font-bold'>{ lang.bio.languages[2] } </span>, 
                <span className='text-zinc-500 font-bold'>{ lang.bio.languages[3] } </span>, 
                <span className='text-cyan-500 font-bold'>{ lang.bio.languages[4] } </span>, 
                <span className='text-violet-500 font-bold'>{ lang.bio.languages[5] } </span>,
                <span className='text-blue-600 font-bold'>Typescript </span>, 
                { lang.bio.description[2] }
            </p>
        </div>
    )
}

function QuickFacts(){
    const { lang } = useLanguage()

    return(
        <div className='flex flex-row max-sm:flex-col mt-4 gap-4'>
            <div className='content-center'>
                <p className='text-red-600 font-bold'>{ lang.bio.quickFacts }</p>
                <ul className="list-disc list-inside">
                    {
                        lang.bio.facts.map((fact, index) => {
                            return <li key={index} className='text-left'>{fact}</li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}